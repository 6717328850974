/* SIDE BAR */

#checkMenu{
    display: none;
}

#icon{
    cursor: pointer;
    position: absolute;
    z-index: 630;
    color: white;
    margin-left: 200px;
    background-color: #311962;
    margin-top: 25px;
    border-radius: 50%;
}

#iconMenuClosed{
    cursor: pointer;
    position: absolute;
    z-index: 630;
    color: white;
    margin-left: 200px;
    background-color: #311962;
    margin-top: 70px;
    border-radius: 50%;
}

.bar{
    background-color: #311962;
    overflow: auto;
    height: 100vh;
    width: 250px;
    position: fixed;
    transition: all 0.1s linear;
    left: -200px;
    display: flex;
    padding: 0;
    justify-content: center;
    top: 0px;
    z-index: 300;
    box-shadow: 0px 0px 20px -8px black;

}


::-webkit-scrollbar-track {
    background-color: #ececec;
}
::-webkit-scrollbar {
    width: 0.40rem;
    background: #311962;
}
/* ::-webkit-scrollbar-thumb {
    background: #311962;
} */

.bar nav{
    width: 100%;
    position: absolute;
    top: 15px;
    color: white;
}


#checkMenu:checked ~ .bar{
    /* width: 15%; */
    z-index: 600;
    top: 0;
    height: 100vh;
    transform: translateX(200px);
    padding-top: 45px;
}

#divLogoMenu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#sideBarClose{
    z-index: 1;
    width: 100%;
    float: right;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 70px;
    padding-top: 45px
}

#sideBarClose div{
    width: 20%;
    padding: 0.5rem;
    padding-right: 0.7rem;
    padding-left: 0.9rem;
    transition: .1s;
    cursor: pointer;
    margin: 0;
}

#sideBarClose a{
    margin-right: 0rem;
    width: 3.1rem;
}

#sideBarClose a div{    
    width: 100%;
    display: flex;
    padding-right: 0.6rem;
    padding-left: 0.3rem;
    align-items: center;
    justify-content: center;
}

#sideBarClose div:hover{
    background-color: #5e33b5;
}

.setNone{
    display: none;
}

.setFlex{
    display: flex;
}

.setShow {
    padding: 0;
}

.chevronIcon{
    margin-left: 0.2rem;
}

/* SIDE BAR ACCORDION AND SIMPLE ITEMS BAR*/

.accordions{
    transition: .1s;
    width: 100%;
    position: relative;
    padding: 0;
    overflow-x: hidden;
}

.accordion-item{
    position: relative;
    width: 100%;
}

.accordion-item input{
	display:none;
    transition: .2s;
}

.accordion-item label{
    position: relative;
    display: flex;
    cursor:pointer;
    width: 100%;
    transition: .1s;
    padding-top: 0.6rem;
    padding-bottom: 0.5rem;
    align-items: center;
}

.accordion-item label p{
    margin-left: 0.5rem;
    font-size: 0.9rem;
}

.accordion-item label div{
    margin-left: 0.9rem;
}


.accordion-item label:hover{
    background-color: #5e33b5;
    transition: .2s;
}

.accordion-content{
    transition: .2s;
    font-size: 0.9rem;
    height: 0px;
    overflow: hidden;
    background-color: #311962;
    -webkit-transition: height 0.3s ease-in-out;
    -moz-transition: height 0.3s ease-in-out;
    -o-transition: height 0.3s ease-in-out;
    -ms-transition: height 0.3s ease-in-out;
    cursor: pointer;
 }

.accordion-content a{
    text-decoration: none;
    color: white;
    width: 100%;
}

.accordion-content:hover{
    background-color: #5e33b5;
}
 
[id^=option]:checked ~ .accordion-content {
    transition: .2s;
    height: auto;
    padding: 0.5rem 0.2rem 0.5rem 2.7rem;
}

.simple-item{
    width: 100%;
    text-decoration: none;
}

.simple-item a{
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.simple-item div{
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 0.2rem;
    transition: 0.2s;
    padding: 0.6rem;
    padding-left: 0.9rem;
}

.simple-item div p{
    padding-left: 0.6rem;
}

.simple-item:hover{
    background-color: #5e33b5;
}


/* TOP BAR */

.topBar{
    z-index: 500 !important;
    background-color: #311962;
    border-bottom: 1px solid #311962;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    box-shadow: 0px 0px 20px -8px black;
}

.topBar img{
    color: white;
    font-style: normal;
    margin: 0;
    float: left;
    justify-content: center;
    margin-left: 0.4rem;
}

.topBar img:hover {
    filter: brightness(0.90);
    cursor: pointer;
}

.topBar h2{
    color: white;
    font-style: normal;
    font-weight: 500;
    margin: 0;
}

.topBar div:nth-child(3){   
    padding-left: 1.8rem;
}

.userIcon{
    margin-right: 10px;
    cursor: pointer;
}

.backgroundSelected{
    background-color: #5e33b5 !important;
}

.selectedItem{
    background-color: #5e33b5;
    color: white!important;
    width: 100% !important;
}

.selectedItemClose{
    background-color: #5e33b5;
    color: white!important;
    width: 22% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formatLabel {
    font-size: 0.9rem;
}
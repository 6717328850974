.notFoundBody{
    width: 100vw;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.notFoundBody h1{
    margin: 0px !important; 
    padding: 0px !important;
}

.notFoundBody hr{
    width: 2rem;
}

.notFoundBody button{
    padding: 0.3rem;
    cursor: pointer;
    background-color: #c3c3c3;
    border: 1px solid #c3c3c3;
    border-radius: 3px;
    transition: 0.2s;
}

.notFoundBody button:hover{
    background-color: #d4d1d1;
}
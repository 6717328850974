@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body{
    font: 400 14px Open Sans, sans-serif;
    background: #fff;
    -webkit-font-smoothing: antialiased;
    height: 100vh;
}

#root{
    height: 100%;
}

.conteudo{
    padding-top: 3rem;
    display: flex;
    justify-content: center;
    width: auto;
    height: 100%;
}

.imagem_ini_menorResolu{
    display: none;
    margin-top: 100px;
    opacity: 0.5;
}

h1{
    color: #3a3a3a;
}


@media screen and (max-width: 750px) {
    body{
        margin: 0;
        padding: 0;
        font: 400 14px Open Sans, sans-serif;
        background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.2)),url(#);
        background-position: center; /* Center the image */
        background-repeat: no-repeat; 
        background-size: 60%;
    }
    .imagem_ini_menorResolu{
        display: block;
    }

    .conteudo{
        flex-direction: column;
    }
}

.LoadingAfterEnter{
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}


.configLoading{
    display: none;
}

.LoadingAfterEnterBgFullPage{
    background-color: white !important;
}

.LoadingAfterEnter {
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: white;
}

.lds-dual-ring {
    display: inline-block;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 6px solid #311962;
    border-color: #311962 transparent #311962 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.SelectedCompanyBranchFooter{
    position: fixed;
    width: calc(100vw);
    height: 2rem;
    background-color: #ececec;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    bottom:0; 
    z-index: 1;
}

.SelectedCompanyBranchFooterleft{
    position: fixed;
    width: calc(100vw);
    height: 2rem;
    background-color: #ececec;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    bottom:0 ;
    padding-right: 13.5rem;
    z-index: 1;
}
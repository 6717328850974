#myProfileMenu{
    background-color: #311962;
    width: 13rem;
    position: absolute;
    float: right;
    right: 0;
    top: 0;
    padding-top: 4rem;
    padding-bottom: 1rem;
    box-shadow: 0px 0px 20px -8px black;
    border-radius: 0px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    z-index: 400 !important;
}

#myProfileMenu div{
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0;
}

#myProfileMenu div img{
    width: 4rem;
    height: 4rem;
}

#myProfileMenu h5{
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
}

#myProfileMenu p{
    color: white;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
}

#myProfileMenu p:hover{
    color: rgba(255, 255, 255, 0.664);
}

#resetPassLink{
    color: white;
}

#resetPassLink:hover{
    color: rgba(255, 255, 255, 0.664);
}

#myProfileHidden{
    display: none;
}
.useTerms-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.useTerms-content {
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  box-shadow: 0px 0px 20px 4px #cccccc;
  border-radius: 1rem;
  border: 1px solid #005F9E;
}

.useTerms-content h2{
  color: #005F9E;    
}

.useTerms-textBox {
  box-shadow: inset 0px 0px 20px 1px #cccccc;
  border-radius: 1rem;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
  border: 1px solid #005F9E;
}

.useTerms-text {
  margin: 0.5rem;
  overflow-y: scroll;
  height: 20rem;
  padding-right: 1rem;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.useTerms-text div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.useTerms-text div p:first-child {
  font-weight: bold;
}

.accept-terms {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.accept-terms button {
  padding: 0.5rem 1rem;
}


.accept-terms button:nth-child(1) {
    background-color: #005F9E;
    color: white;
    border: 1px solid #005F9E;
    border-radius: 0.5rem;
    cursor: pointer;
}

.accept-terms button:nth-child(1):hover{
    background-color: white;
    color: #005F9E;
    transition: .2s;
}

.accept-terms button:nth-child(2) {
    background-color: #b62525;
    color: white;
    border: 1px solid #b62525;
    border-radius: 0.5rem;
    cursor: pointer;
}

.accept-terms button:nth-child(2):hover{
    background-color: white;
    color: #b62525;
    transition: .2s;
}


.termOfUseLoading{
  height: 10rem;
}


.ldsDualRing {
    display: inline-block;
    /* width: 80px;
    height: 80px; */
}
.ldsDualRing:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    /* margin: 8px; */
    border-radius: 50%;
    border: 6px solid #311962;
    border-color: #311962 transparent #311962 transparent;
    animation: ldsDualRing 1.2s linear infinite;
}
@keyframes ldsDualRing {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
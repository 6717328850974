#containerLogon {
    overflow: hidden;
    opacity: 1;
    display: grid;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-template-columns: 40% 1px 40%;
    width: 100vw;
    height: 100vh;
    background: #fff;
}

.logoContainer {
    animation: Slide_right 3s ease;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #010101 !important;
    gap: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    padding-left: 15rem;
    transition: 0.1s;
    background-color: none;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #311962;
    opacity: 1; /* Firefox */
  }

#containerLogon input{
    color: #311962;
    width: 220px;
    height: 30px;
    margin: 0.7rem;
    font-size: 15px;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    border-radius: 0.3rem;
    background-color: #fff;
    border: none;
    outline: none;
    border-bottom: 0.1rem solid #311962;
}

#containerLogon button{
    margin: 0.7rem;
    width: 90px;
    height: 30px;
    font-size: 15px;
    background-color: #311962;
    color: #fff;
    border-radius: 0.3rem;
    border: 1px solid #311962;
    cursor: pointer;
    transition: 0.3s;
}

.loginSeparator {
    z-index: 100;
    height: 16rem;
    width: 4px;
}

.loginSeparator:after{
    content: '';
    height: 100%;
    border-right: 1px solid #311962;
    display: block;
    width: 0px;
    border-radius: 50%;
}


#containerLogon button:hover{
    background-color: #fff;
    color: #311962;
}

.inputContainer {
    height: 15rem;
    z-index: 200;
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-right: 25rem;
    animation: Slide_left 3s ease;
}

.inputContainer form{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;    
}

.logoId {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #311962;
    bottom: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.9rem;
    justify-content: center;
    position: absolute;
    filter: brightness(0.8);
    cursor: pointer;
}

.inLoginEvent {
    animation: override 1.8s ease;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #010101;
    gap: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    padding-left: 15rem;
    transition: 0.1s;
}

.inProcessLoading {
    display: flex;
    opacity: 0;
    align-items: center;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.815);
    gap: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    padding-left: 15rem;
    transition: 0.1s;
}

.loginLoaderHide {
    display: none;
}


.loginLoaderShow {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    animation: opacity 1s ease;
    margin-right: 6rem;
}

@keyframes Slide_right{
    40%{margin-left: 0px;
         opacity: 1;
        transition: 0.1s;}
    0%{margin-left: -400px;
       opacity: 0;
       transition: 0.1s}
}

@keyframes Slide_left{
    40%{margin-right: 0px;
         opacity: 1;
        transition: 0.1s;}
    0%{margin-right: -400px;
       opacity: 0;
       transition: 0.1s}
}
@keyframes override{
    100%{margin-right: -700px;
         opacity: 0;
         position: relative;
        transition: 0.1s;}
    0%{margin-left: 0;
       opacity: 1;
       position: relative;
       z-index: 1;
       transition: 0.1s}
}

@keyframes opacity {

    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
    
}



#logoPrimeIMG {
    width: 6rem;
}

@media(max-width: 1570px){
    #containerLogon {
        grid-template-columns: 49% 2% 49%;
    }
}


@media(max-width: 1366px){
    #containerLogon {
        grid-template-columns: 49.6% 0.074% 49.6%;
    }
    .logoId{ 
        font-size: 0.7rem;
    }   

    #logoPrimeIMG  {
        width: 5rem;
    }


    .logoContainer {
    
        animation: Slide_right 3s ease;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #010101 !important;
        gap: 0.8rem;
        font-size: 1.4rem;
        font-weight: 400;
        padding-left: 15rem;
        transition: 0.1s;

        background-color: none;
        
    }
    
    
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #311962;
        opacity: 1; /* Firefox */
      }
    
    #containerLogon input{
        width: 220px;
        height: 30px;
        margin: 0.7rem;
        font-size: 15px;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        border-radius: 0.3rem;
        background-color: #fff;
        border: none;
        outline: none;
        border-bottom: 0.1rem solid #311962;
        color: #311962 !important;
    }
    
    #containerLogon button{
        margin: 0.8rem;
        width: 4.5rem;
        height: 1.9rem;
        font-size: 14px;
        background-color: #311962;
        color: #fff;
        border-radius: 0.3rem;
        border: 1px solid #311962;
        cursor: pointer;
        transition: 0.3s;
    }
    
    .loginSeparator {
        z-index: 100;
        height: 15rem;
        width: 4px;
    }
    
    .loginSeparator:after{
        content: '';
        height: 100%;
        border-right: 1px solid #311962;
        display: block;
        width: 0px;
        border-radius: 50%;
    }
    
    
    #containerLogon button:hover{
        background-color: #fff;
        color: #311962;
    }
    
    .inputContainer {
        height: 14rem;
        z-index: 200;
        background: #fff;
        /* background: pink; */
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-right: 20rem;
        animation: Slide_left 3s ease;
    }

    .inputContainer form{
      display: flex;
      flex-direction: column;
      align-items: center;
      align-items: center;    
    }
    
    .logoId {
        display: flex;
        flex-direction: row;
        width: 100%;
        color: #010101;
        bottom: 0.5rem;
        gap: 0.5rem;
        align-items: center;
        font-size: 0.9rem;
        justify-content: center;
        position: absolute;
        filter: brightness(0.8);
        cursor: pointer;
    }
    
    .inLoginEvent {
        animation: override 1.8s ease;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #010101;
        gap: 0.8rem;
        font-size: 1.4rem;
        font-weight: 400;
        padding-left: 15rem;
        transition: 0.1s;
    }
    
    .inProcessLoading {
        display: flex;
        opacity: 0;
        align-items: center;
        flex-direction: column;
        color: rgba(255, 255, 255, 0.815);
        gap: 2rem;
        font-size: 1.5rem;
        font-weight: 400;
        padding-left: 15rem;
        transition: 0.1s;
    }
    
    .loginLoaderHide {
        display: none;
    }
    
    
    .loginLoaderShow {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center;
        margin-right: 6rem;
        animation: opacity 1s ease;
    }
    
}

@media(max-width: 1230px){
    #containerLogon {
        grid-template-columns: 45% 5% 45%;
    }
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #311962;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  